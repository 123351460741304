import React, { useEffect } from "react";
import { Formik } from "formik";
import { Box, Grid } from "@mui/material";
import { FormInput } from "src/kit/Form";
import { FormContainer } from "../../style";
import FormController from "src/controllers/FormController";
import { useSelector } from "react-redux";
import { VerifyValidationOrganization } from "src/validation/schemas/verifyValidationOrganization";
import { useDispatch } from "react-redux";
import HyperledgerFabricActions from "src/redux/HyperledgerFabric/Actions";



const Network = ({ showSetting, setShowSetting }) => {
    const dispatch = useDispatch();

    const organizationList = useSelector((state) => state.HyperledgerFabric.organizationList);

    useEffect(() => {

    }, [organizationList])
    return (
        <Formik
            initialValues={{
                networkName: "",
                orgNumInCompany: "",
            }}
            isInitialValid={false}
            validationSchema={VerifyValidationOrganization}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                setShowSetting(true);
                dispatch(HyperledgerFabricActions.setOrganizarionInfo({
                    orgNumInCompany: values.orgNumInCompany, networkName: values.networkName
                }))
            }}
        >
            {(formik) => (
                <>
                    <FormContainer>
                        <Grid container display="flex" justifyContent="end">
                            <Grid item p={2} xs={12} md={6}>
                                <Box className="form_lable">نام شبکه</Box>
                                <FormInput
                                    value={formik.values.networkName}
                                    name="networkName"
                                    id="networkName"
                                    placeholder="نام شبکه"
                                    onChange={(e) => FormController.onChange(e, formik, null)}
                                    className={
                                        "custom_input" + (formik.errors.networkName && formik.touched.networkName ? " warning-border" : "")
                                    }
                                    disabled={showSetting ? true : false}
                                />
                                {formik.errors.networkName && formik.touched.networkName ? (
                                    <div className="text-alert">{formik.errors.networkName}</div>
                                ) : <div className="text-alert"></div>}

                            </Grid>
                            <Grid item p={2} xs={12} md={6}>
                                <Box className="form_lable">تعداد سازمان در شبکه</Box>
                                <FormInput
                                    value={formik.values.orgNumInCompany}
                                    name="orgNumInCompany"
                                    id="orgNumInCompany"
                                    placeholder="تعداد سازمان در شبکه"
                                    onChange={(e) => FormController.onChange(e, formik, null)}
                                    className={
                                        "custom_input" + (formik.errors.orgNumInCompany && formik.touched.orgNumInCompany ? " warning-border" : "")
                                    }
                                    disabled={showSetting ? true : false}
                                />
                                {formik.errors.orgNumInCompany && formik.touched.orgNumInCompany ? (
                                    <div className="text-alert">{formik.errors.orgNumInCompany}</div>
                                ) : <div className="text-alert"></div>}

                            </Grid>
                            <Grid item xs={4} p={2} md={4} lg={2}>
                                <button
                                    color="primary"
                                    size="lg"
                                    onClick={() => { formik.handleSubmit(); }}
                                    className="ml-1 btn-form"
                                    type="submit"
                                >
                                    <Box className="color-white" p={2}>ثبت</Box>
                                </button>
                            </Grid>
                        </Grid>
                    </FormContainer>
                </>
            )}
        </Formik>
    )
}

export default Network