import types from './Types';
import DelegateAction from '../ActionDelegator';

const HyperlegerFabricActions = {
  setOrganizationList : DelegateAction (types.SET_ORGANIZATION_LIST),
  setNextHyperLegerStep : DelegateAction( types.SET_NEXT_HYPERLEGER_FABRIC_STEP),
  setOrganizationsPeersList : DelegateAction( types.SET_ORGANIZATIONS_PEERS_LIST),
  setOrganizarionInfo : DelegateAction(types.SET_ORGANIZATIONS_INFO),

  getHyperLedgerDetails: DelegateAction(types.GET_HYPER_LEDGER_DETAILS),
  getHyperLedgerList: DelegateAction(types.GET_HYPER_LEDGER_lIST),
  addHyperLedger: DelegateAction(types.ADD_HYPER_LEDGER),
  setOrdererInfo : DelegateAction(types.SET_ORDERER_INFO),
  setOrganizationPeersOption : DelegateAction(types.SET_ORGANIZATION_PEERS_OPTIONS),
  resetHyperFabricState : DelegateAction(types.RESET_HYPER_FABRIC_STATE),
  setShowResultModal : DelegateAction(types.SET_SHOW_RESULT_MODAL)

};

export default HyperlegerFabricActions;