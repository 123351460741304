import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
:root {
  overflow-x:hidden;
  font-size : ${(props) => (props.theme.fontSizeSm)};
  font-family: ${(props) => (props.theme.fontDefault)};
  direction:rtl;
};

*{
  font-family: ${(props) => (props.theme.fontDefault)};
}
#api_key{
  font-family: sans-serif !important;
}
body {
  background-color : #14213D;
  overflow-x : hidden;
  margin :0;
  font-family: ${(props) => (props.theme.fontDefault)};

}
.form-label{
  margin-bottom:10px;
  font-family: ${(props) => (props.theme.fontBold)};
}
.clickable{
  cursor : pointer;
}
.MuiBackdrop-root{
  background-color: rgb(15 24 63 / 89%) !important;
}
.Toastify__close-button{
  width :20px;
}
#endpoint-table{
  .bict__tblRow:first-child {
    background: #dce4f5;
  }
}
.warning-border{
  border :1px solid red !important;
}
.text-danger{
  color :red;
  font-size:12px;
}

.text-alert{
  color : red;
  font-size: 10px;
  width: 100%;
  height :20px;
}
#reject_btn button{
  background-color: #fff !important;
  color: #FCA311;
}
////////////////////////////////input
.input-container {
  position: relative;
  width: 100%; /* Adjust width as needed */
  margin: 3px auto; /* Adjust margin as needed */
}

.input-container input[type="text"] ,.input-container input[type="password"]{
  width: 100%;
  padding: 10px 100px 10px 10px; /* Adjust padding to accommodate icon */
  border: 1px solid #DCDCDC;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
}

.input-container .icon {
  position: absolute;
  left: 10px; /* Adjust position of icon */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.input-container .placeholder-text {
  position: absolute;
  right: 10px; /* Adjust position of placeholder text */
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}
//////////////////////////tooltip

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color:${props => props.theme.textPrimaryDark}; 
  text-align: center;
  border-radius: ${props => props.theme.primaryBorderRadius};
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 20px;
  box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
/////////////////////////////////////////hichart style

.highcharts-exporting-group {
 display : none;
}
 .highcharts-subtitle{
 display : none;
 }
 .highcharts-credits{
 display : none;
 }

.highcharts-axis-title , .highcharts-data-label{
 font-family: ${(props) => (props.theme.fontDefault)};

}
////////////////////////////////////////scroll bar
/* width */
::-webkit-scrollbar {
  width: 7px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 15px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #AFAEAE;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${(props) => (props.theme.lightGary)};
}


//////////////////////////////////
.loader {
  color: #14213D;
  font-size: 35px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 15px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
`;
