import React from 'react';
import { StyledSubMenuWrapper } from './style';

const StyledSubMenu = (props) => {
  const { visible, placement, width, height } = props;

  return (
    <StyledSubMenuWrapper
      visible={visible}
      placement={placement}
      width={width}
      height={height}
      className="subMenu"
    >
      <div className="square" />
      {props.children}
    </StyledSubMenuWrapper>
  );
};
export default StyledSubMenu;
