import types from './Types';
import DelegateAction from '../ActionDelegator';

const EndpointsActions = {
  setshowEndpointDetailModal: DelegateAction(types.SET_SHOW_ENDPOINT_DETAIL_MODAL),
  getEndpointsList : DelegateAction(types.GET_ENDPOINTS_LIST),
  addEndpoint : DelegateAction(types.ADD_ENDPOINT),
  getEndpointById : DelegateAction(types.GET_ENDPOINT_BY_ID),
  setShowGenerateApiKeyModal : DelegateAction(types.SET_SHOW_GENERATE_API_KEY_MODAL),
  getDetailsStatusBasedChart : DelegateAction(types.GET_DETAILS_STATUS_BASED_CHART),
  getBlockchainsList : DelegateAction(types.GET_BLOCKCHAIN_LIST),
  getNetworksList : DelegateAction(types.GET_NETWORKS_LIST),
  generateApiKey : DelegateAction(types.GENERATE_APIKEY),
  setShowApiKeyModal : DelegateAction(types.SET_SHOW_API_KEY_MODAL),
  downloadDocument : DelegateAction(types.DOWNLOAD_DOCUMENT),
  getDetailsCuBasedChart :DelegateAction(types.GET_DETAILS_CU_BASED_CHART),
  getEndpoints : DelegateAction(types.GET_ENDPOINTS),
  getEditDedicated :DelegateAction(types.GET_EDIT_DEDICATED),
  postEditDedicated : DelegateAction(types.POST_EDIT_DEDICATED),
  setShowEditDedicatedModal : DelegateAction(types.SHOW_EDIT_DIDICATED_MODAL)

};

export default EndpointsActions;