import * as Yup from "yup";
const requireMessage = "این فیلد الزامی است.";
const emailRegex = "/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim"

export const string = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.string()
            .label(label)
            .typeError(requireMessage)
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
};


export const email = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.string()
            .label(label)
            .matches(/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim ,  "فرمت ایمیل صحیح نمی باشد")
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
}


export const number = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.string()
            .label(label)
            .matches(/(^[0-9]+$)/, "فقط شامل اعداد می باشد.")
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
};

export const boolean = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.boolean()
            .label(label)
            .required(requireMessage);
    } else {
        return Yup.boolean().label(label).nullable(true);
    }
};
