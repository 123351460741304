import React from "react";
import { useSelector } from "react-redux";
import ChannelSetting from "./channelSetting";

const Channelist = () => {
    const state = useSelector((state) => state.HyperledgerFabric);
    const {  organizationsListOptions ,organizationsPeersOptions } = state

    return (
        <>
            <ChannelSetting
                organizationsListOptions={organizationsListOptions}
                organizationsPeersOptions={organizationsPeersOptions}
            />
        </>
    )
}

export default Channelist