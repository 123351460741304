
import styled from "styled-components";
import Row from "src/kit/Row";
import Col from "src/kit/Column";
import { Link } from "react-router-dom";
export const SubSystemWrapper = styled(Col)`
align-items: center;
box-shadow: 0px 1px 3px 1px #e6e6e6;
  justify-content: flex-end;
  width: 200px;
  background-color: white;
  height: calc(100% - 0px);

  position: relative;
  padding :50px 10px;
  box-sizing : border-box;;
  .o__subSystemTitle {
    color: ${(props) => props.theme.primary};
    border-bottom: ${(props) => props.theme.borderPrimary};
    width: 230px;
    justify-content: center;
    height: 40px;
  }
  #credit{
    color :  ${(props) => (props.theme.textLight)} !important;
    font-size : ${(props) => (props.theme.fontSizeLg)};
    width :70%;
    text-align :center;
    font-family: ${(props) => (props.theme.fontDefault)};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => (props.theme.fontDefault)};
}
`;

export const SubSystem = styled(Col)`
  width: 100%;
  height: 110px;
  background-color :${(props) =>props.theme.primaryDark}
`;


export const LogoContainer = styled(Col)`
  justify-content: center;
  box-sizing: border-box;
  width: 75%;
  height: 95px;
  background-image: url(${props => props.src});
  background-size : contain;
  background-repeat :  no-repeat;
  margin :10px 0px;
  background-position: center;
`;

export const SubSystemContainer = styled(Col)`
  margin-top:65px;
  justify-content: flex-start;
  height: calc(100% - 75px);
  width :100%;
  border-bottom : 1px solid #ccc;
`;

export const SubSystemItemContainer = styled(Col)`
  height: auto;
  width: 100%;
  display: ${(props) => (props.title === "profile" && "none")};
  border-right:  ${(props) => (props.active ? `5px solid ${props.theme.primaryDark}` : "none")};
  padding : 6px 0;
  background-color : ${(props) => (props.active ? `#E8EDF8` : "transparent")}; ;
  svg{
    width:20px;
    height :20px;
  }
 
`;
export const SubSystemItem = styled(Link)`
  // border-bottom : 1px solid ${(props) => props.theme.primaryLight};
  width: 100%;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  // padding-right: 10px;
  justify-content: flex-start;
  position: relative;

  color: ${(props) => (props.active ? props.theme.secondary : props.theme.textPrimary)};
  font-size : ${(props) => (props.theme.fontSizeMd)};
  font-family: ${(props) => (props.theme.fontDefault)};
  cursor: pointer;
  span {
    margin-right: 10px;
    color :${(props) => (props.active ?  props.theme.primaryDark : props.theme.secondaryLight)};
    font-size: ${(props) => (props.theme.fontSizeLg)};
  }
 
  .o__subSystem__chevronIcon {
    justify-content: center;
    align-items: center;
    transform: ${(props) => props.active && "rotate(180deg)"};
    position: absolute;
    left: 0px;
    svg {
      width: 100%;
      g path {
        fill: ${(props) => (props.active ? props.theme.secondary : "red")};
        stroke: ${(props) => (props.active ? props.theme.secondary : "red")};
        g: ${(props) => (props.active ? props.theme.secondary : "red")};

      }
    }
  
  }
`;
export const SubSystemMenusContainer = styled(Col)`
  margin: 0px 5px 0px 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  a:hover span{
    color:${(props) => ( props.theme.textSecondary)};
  }
`;
export const SubSystemMenusItem = styled(Link)`
  margin: 5px 0px 5px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: ${(props) => props.active && props.theme.fontBold};
  font-size: ${(props) => props.theme.fontSizeMd};
  color: ${(props) => (props.active ? props.theme.primaryDark : props.theme.secondaryLight)};
  padding-right:30px;
`;
export const TitleContainer = styled.div`
color : ${props => props.theme.textPrimary};
font-size : ${props => props.theme.fontSizeMd};
padding :5px 0px;
`;
export const ExitContainer = styled.div`
color : ${props => props.theme.textPrimary};
font-size : ${props => props.theme.fontSizeSm};
border: 1px solid ${props => props.theme.textPrimary};
    padding: 5px 20px;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: ${props => props.theme.primaryBorderRadius};
`;
export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: ${(props) => props.size || "15px"};
  height: ${(props) => props.size || "15px"};
  svg {
    width: ${(props) => props.size || "15px"};
    height: ${(props) => props.size || "15px"};
  }
`;

export const AvatarLogoContainer = styled(Col)`
  justify-content: center;
  box-sizing: border-box;
  width: 65%;
  height: 75px;
  background-image: url(${props => props.src});
  background-size : contain;
  background-repeat :  no-repeat;
  margin :10px 0px;
  background-position: center;
  #company-logo{
    width :70px;
  }
`;
