import React from "react";
import * as Yup from "yup"; // Yup for validation
import crtl from "../../hyperledger.controllers";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import OrgInfo from "./peersInfo";
import { Formik } from "formik";

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  entries: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      peers: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("این فیلد الزامی می باشد"),
          port: Yup.string().required("این فیلد الزامی می باشد"),
        })
      )
    })
  )
});

const Peer = () => {
    const { handleSubmitOrganizationsPeers } = crtl();
    const organizationList = useSelector((state) => state.HyperledgerFabric.organizationList);

    const initialValues = {
        entries: organizationList.map(item => ({
            name: item.orgName,
            mspId : item.MSPId,
            peers: Array.from({ length: item.peerNum }, () => ({
                name: "",
                port: "",
            }))
        }))
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}  
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                handleSubmitOrganizationsPeers(values.entries);
            }}
        >
            {(formik) => (
                <form className="py-5" onSubmit={formik.handleSubmit}>  
                    <Grid container>
                        {formik.values.entries.map((entry, entryIndex) => {
                            return (
                                <Grid item xs={12} md={6} xl={4} p={2} key={entryIndex}>
                                    <Box>{entry.name}</Box>
                                    <OrgInfo formik={formik} entry={entry} entryIndex={entryIndex} />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid container display="flex" justifyContent="end">
                        <Grid item xs={4} p={2} md={4} lg={2}>
                            <button
                                color="primary"
                                size="lg"
                                type="submit" 
                                className="ml-1 btn-form"
                            >
                                <Box className="color-white" p={2}>بعدی</Box>
                            </button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}

export default Peer;


