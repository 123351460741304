
import Row from "src/kit/Row";
import { Link } from "react-router-dom";


const HyperLedgerFabricTableConfig = () => {
  return {
    showHeader: true,

    row: [
      {
        headerTitle: "ردیف",
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: "نام",
        displayTitle: "name",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Link
            to={{ pathname: `/dedicatedNetwork/hyperledgerFabric/detail/${props.row.id}`, state: { id: props.row.id } }}>
            <Row>
              {props.row.name !== "" ? props.row.name : "-"}
            </Row>
          </Link>
          );
        },
      },
      {
        headerTitle: "وضعیت",
        displayTitle: "status",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.status !== "" ? props.row.status : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "تعداد سازمان",
        displayTitle: "organizationsCount",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
             {props.row.organizationsCount !== "" ? props.row.organizationsCount : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "تعداد کانال",
        displayTitle: "channelsCount",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
                {props.row.channelsCount !== "" ? props.row.channelsCount : "-"}
            </Row>
          );
        },
      },
    ],
  };
};
export default HyperLedgerFabricTableConfig;