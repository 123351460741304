import React, { useEffect, useState } from "react";
import { DetailContainer } from "../styles"
import { CardContainer, TitleContainer } from "src/shared/generalStyles";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EndpointsActions from "src/redux/Endpoint/Actions";
import { ChartContainer } from "src/shared/generalStyles";
import VirtualizedSelect from 'react-virtualized-select';
import LineChart from "src/charts/LineChart";
import moment from "moment-jalaali";
import ColumnChart from "src/charts/ColumnChart";
import {useParams} from "react-router-dom"

const ListOptions = [
    { value: 1, label: "یک ساعت گذشته" },
    { value: 2, label: "یک روز گذشته" },
    { value: 3, label: "یک هفته گذشته" },
    { value: 4, label: "یک ماه گذشته" },
]

const EndpointDetail = (props) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const endpointDetail = useSelector((state) => state.Endpoint.endpointDetail);
    const detailsStatusBasedChart = useSelector((state) => state.Endpoint.detailsStatusBasedChart);
    const detailsCuBasedChart = useSelector((state) => state.Endpoint.detailsCuBasedChart);


    const [selected, setselected] = useState(ListOptions[0]);
    const [selectedCu, setselectedCu] = useState(ListOptions[0]);
    const [StatusData, setStatusData] = useState([]);
    const [cuData, setCuData] = useState([]);
    const [years, setyears] = useState([]);
    const [yearsCu, setyearsCu] = useState([]);


    useEffect(() => {
        dispatch(EndpointsActions.getEndpointById({ id: id }));
        dispatch(EndpointsActions.getDetailsStatusBasedChart({ id: id, time: 1 }));
        dispatch(EndpointsActions.getDetailsCuBasedChart({ id: id, time: 1 }));

    }, []);
    ///////////////////////////////////status
    useEffect(() => {
        renderStatusChartData(detailsStatusBasedChart);
    }, [selected])

    useEffect(() => {
        renderStatusChartData(detailsStatusBasedChart);
    }, [detailsStatusBasedChart])
    ///////////////////////////////////cu
    useEffect(() => {
        renderCuChartData(detailsCuBasedChart);
    }, [selectedCu])

    useEffect(() => {
        renderCuChartData(detailsCuBasedChart);
    }, [detailsCuBasedChart])
    /////////////////////////////////////
    useEffect(() => {

    }, [endpointDetail])

    const renderStatusChartData = (data) => {
        let successfulList = [];
        let failedList = [];
        let dateList = [];
        let result = [];

        if (data.length > 0) {
            data.map((each, index) => {
                successfulList.push(each.successful);
                failedList.push(each.failed);
                selected.value === 1 || selected.value === 2 ? dateList.push(moment(each.date).format("HH:mm")) :
                    dateList.push(moment(each.date).format("jYYYY-jMM-jDD"));
            })
        }

        if (failedList.length === 0 && successfulList.length === 0) {
            result = [];
        }
        else {
            result = [
                {
                    name: 'ناموفق',
                    data: failedList
                },
                {
                    name: 'موفق',
                    data: successfulList
                }
            ]
        }
     
            setStatusData(result);
            setyears(dateList);
        
    }

    const renderCuChartData = (data) => {
        let computationUnitsList = [];
        let dateList = [];
        let result = [];

        if (data.length > 0) {
            data.map((each, index) => {
                computationUnitsList.push(each.computationUnits);
                selectedCu.value === 1 || selectedCu.value === 2 ? dateList.push(moment(each.date).format("HH:mm")) :
                    dateList.push(moment(each.date).format("jYYYY-jMM-jDD"));
            })
        }

        if (computationUnitsList.length === 0 ) {
            result = [];
        }
        else {
            result = [
                {
                    name: 'تعداد واحد محاسبه',
                    data: computationUnitsList
                }
            ]
        }
            setCuData(result);
            setyearsCu(dateList);
    }
    return (
        <CardContainer>
            <DetailContainer>

                {endpointDetail !== null &&
                    <Grid container>
                        <Grid item md={12} py={2}>
                            <TitleContainer paadingtop="0px">{props.name} | {props.description}</TitleContainer>
                        </Grid>
                        <Grid item md={2} p={1}>
                            <Box className="box-container">
                                <Box className="text_bold">
                                    {endpointDetail?.lastDayStatusPercentage ? endpointDetail.lastDayStatusPercentage.toFixed(2) : "0"}%</Box>
                                <Box>نرخ موفقیت درخواست‌ها (24 ساعت گذشته)</Box>
                            </Box>
                        </Grid>
                        <Grid item md={2} p={1}>
                            <Box className="box-container">
                                <Box className="text_bold">
                                    {endpointDetail?.lastDayTotalRequests !== undefined ? endpointDetail.lastDayTotalRequests : "-"}
                                </Box>
                                <Box>کل درخواست‌ها (24 ساعت گذشته)</Box>
                            </Box>
                        </Grid>
                        <Grid item md={2} p={1}>
                            <Box className="box-container">
                                <Box className="text_bold">
                                    {endpointDetail?.lastDayAverageResponseTime !== undefined ? endpointDetail.lastDayAverageResponseTime : "-"} میلی ثانیه</Box>
                                <Box>متوسط زمان پاسخ درخواست‌ها (24 ساعت گذشته)</Box>
                            </Box>
                        </Grid>
                        <Grid item md={2} p={1}>
                            <Box className="box-container">
                                <Box className="text_bold">
                                    {endpointDetail?.last5MinStatusPercentage ? endpointDetail.last5MinStatusPercentage.toFixed(2) : "0"}%</Box>

                                <Box> نرخ موفقیت درخواست‌ها (5 دقیقه گذشته)</Box>
                            </Box>
                        </Grid>
                        <Grid item md={2} p={1}>
                            <Box className="box-container">
                                <Box className="text_bold">{endpointDetail?.last5MinTotalRequests !== undefined ? endpointDetail?.last5MinTotalRequests : "-"}</Box>
                                <Box>کل درخواست‌ها (5 دقیقه گذشته)</Box>
                            </Box>
                        </Grid>
                        <Grid item md={2} p={1}>
                            <Box className="box-container">
                                <Box className="text_bold">
                                    {endpointDetail?.last5MinAverageResponseTime !== undefined ? endpointDetail.last5MinAverageResponseTime : "-"} میلی ثانیه</Box>
                                <Box> متوسط زمان پاسخ درخواست‌ها(5 دقیقه گذشته)</Box>
                            </Box>
                        </Grid>
                    </Grid>}
                <Grid container>
                    <Grid item md={6} p={1}>
                        <Box className="box-container">
                            <ChartContainer>
                                <Grid container>

                                    <Grid item xs={7} p={2} display="flex" justifyContent="start">
                                        وضعیت درخواست ها
                                    </Grid>
                                    <Grid item xs={5} p={2}>
                                        <VirtualizedSelect
                                            noResultsText="موردی وجود ندارد"
                                            value={selected}
                                            placeholder=""
                                            onChange={(selected) => {
                                                setselected(selected);
                                                selected !== null && dispatch(EndpointsActions.getDetailsStatusBasedChart({ time: selected.value, id: props.id }));
                                            }}
                                            options={ListOptions}
                                        />
                                    </Grid>
                                </Grid>
                                <ColumnChart title={""} series={StatusData} years={years} height="400" />
                            </ChartContainer>

                        </Box>
                    </Grid>
                    <Grid item md={6} p={1}>
                        <Box className="box-container">
                            <ChartContainer>
                                <Grid container>
                                    <Grid item xs={7} p={2} display="flex" justifyContent="start">
                                        وضعیت واحدهای محاسبه
                                    </Grid>
                                    <Grid item xs={5} p={2}>
                                        <VirtualizedSelect
                                            noResultsText="موردی وجود ندارد"
                                            value={selectedCu}
                                            placeholder=""
                                            onChange={(selected) => {
                                                setselectedCu(selected);
                                                selected !== null && dispatch(EndpointsActions.getDetailsCuBasedChart({ time: selected.value, id: props.id }));

                                            }}
                                            options={ListOptions}
                                        />
                                    </Grid>
                                </Grid>
                                <ColumnChart series={cuData} years={yearsCu} height="415" />
                            </ChartContainer>
                        </Box>
                    </Grid>
                </Grid>
            </DetailContainer>
        </CardContainer>
    )
}
export default EndpointDetail