import React from "react";
import { Switch, withRouter, Route, Redirect } from "react-router-dom";
import EndpointPage from "./components/EndpointList";
import EndpointDetail from "./components/EndpointDetail";

const MainApp = (props) => {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          component={() => <EndpointPage />}
        />
        <Route
          path={`${match.url}/detail/:id`}
          component={() => <EndpointDetail
            location={props.location}
            id={props.location.state?.id}
            description={props.location.state?.description}
            name={props.location.state?.name} />}

        />
        <Redirect to="/error" />
      </Switch>
    </>
  )
}
export default withRouter(MainApp)