import React from "react";
import { Switch, withRouter, Route, Redirect } from "react-router-dom";
import HyperList from "./hyperList";
import AddHyperFabric from "./addHyper";
import HyperFabricDetail from "./hyperFabricdetail";


const MainApp = (props) => {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          component={() => <HyperList />}
        />
        <Route
          path={`${match.url}/add`}
          component={() => <AddHyperFabric
          />}
        />
        <Route
          path={`${match.url}/detail/:id`}
          component={() => <HyperFabricDetail
            id={props.location.state?.id}
            location={props.location}
          />}

        />
        <Redirect to="/error" />
      </Switch>
    </>
  )
}
export default withRouter(MainApp)