import React, { useEffect, useState } from "react";
import Org from "./orgSetting";
import OerdererSetting from "./oerdererSetting";
import Organization from "./organization";
import { FormContainer } from "../../style";
import { useSelector } from "react-redux";

const Network = ({ }) => {

  const organizationList = useSelector((state) => state.HyperledgerFabric.organizationList);
  const [showSetting, setShowSetting] = useState(false);

  useEffect(() => {

  }, [organizationList])
  return (
    <>
      <Organization showSetting={showSetting} setShowSetting={setShowSetting} />
        {showSetting === true && <>
          <FormContainer>
            <Org  />
          </FormContainer>
          <OerdererSetting  />
        </>
        }
    </>
  )
}

export default Network