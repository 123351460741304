export default {
    SET_SHOW_ENDPOINT_DETAIL_MODAL: 'SET_SHOW_ENDPOINT_DETAIL_MODAL',

    GET_ENDPOINTS_LIST: 'GET_ENDPOINTS_LIST',
    GET_ENDPOINTS_LIST_SUCCESS: 'GET_ENDPOINTS_LIST_SUCCESS',
    GET_ENDPOINTS_LIST_FAIL: 'GET_ENDPOINTS_LIST_FAIL',
    GET_ENDPOINTS_LIST_EXCEPTION: 'GET_ENDPOINTS_LIST_EXCEPTION',
  
    ADD_ENDPOINT: 'ADD_ENDPOINT',
    ADD_ENDPOINT_SUCCESS: 'ADD_ENDPOINT_SUCCESS',
    ADD_ENDPOINT_FAIL: 'ADD_ENDPOINT_FAIL',
    ADD_ENDPOINT_EXCEPTION: 'ADD_ENDPOINT_EXCEPTION',
  
    GET_ENDPOINT_BY_ID: 'GET_ENDPOINT_BY_ID',
    GET_ENDPOINT_BY_ID_SUCCESS: 'GET_ENDPOINT_BY_ID_SUCCESS',
    GET_ENDPOINT_BY_ID_FAIL: 'GET_ENDPOINT_BY_ID_FAIL',
    GET_ENDPOINT_BY_ID_EXCEPTION: 'GET_ENDPOINT_BY_ID_EXCEPTION',

    SET_SHOW_GENERATE_API_KEY_MODAL: 'SET_SHOW_GENERATE_API_KEY_MODAL',
    SET_SHOW_API_KEY_MODAL: 'SET_SHOW_API_KEY_MODAL',

    GET_DETAILS_STATUS_BASED_CHART: 'GET_DETAILS_STATUS_BASED_CHART',
    GET_DETAILS_STATUS_BASED_CHART_SUCCESS: 'GET_DETAILS_STATUS_BASED_CHART_SUCCESS',
    GET_DETAILS_STATUS_BASED_CHART_FAIL: 'GET_DETAILS_STATUS_BASED_CHART_FAIL',
    GET_DETAILS_STATUS_BASED_CHART_EXCEPTION: 'GET_DETAILS_STATUS_BASED_CHART_EXCEPTION',

    GET_BLOCKCHAIN_LIST: 'GET_BLOCKCHAIN_LIST',
    GET_BLOCKCHAIN_LIST_SUCCESS: 'GET_BLOCKCHAIN_LIST_SUCCESS',
    GET_BLOCKCHAIN_LIST_FAIL: 'GET_BLOCKCHAIN_LIST_FAIL',
    GET_BLOCKCHAIN_LIST_EXCEPTION: 'GET_BLOCKCHAIN_LIST_EXCEPTION',

    GET_NETWORKS_LIST: 'GET_NETWORKS_LIST',
    GET_NETWORKS_LIST_SUCCESS: 'GET_NETWORKS_LIST_SUCCESS',
    GET_NETWORKS_LIST_FAIL: 'GET_NETWORKS_LIST_FAIL',
    GET_NETWORKS_LIST_EXCEPTION: 'GET_NETWORKS_LIST_EXCEPTION',

    GENERATE_APIKEY: 'GENERATE_APIKEY',
    GENERATE_APIKEY_SUCCESS: 'GENERATE_APIKEY_SUCCESS',
    GENERATE_APIKEY_FAIL: 'GENERATE_APIKEY_FAIL',
    GENERATE_APIKEY_EXCEPTION: 'GENERATE_APIKEY_EXCEPTION',

    DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
    DOWNLOAD_DOCUMENT_SUCCESS: 'DOWNLOAD_DOCUMENT_SUCCESS',
    DOWNLOAD_DOCUMENT_FAIL: 'DOWNLOAD_DOCUMENT_FAIL',
    DOWNLOAD_DOCUMENT_EXCEPTION: 'DOWNLOAD_DOCUMENT_EXCEPTION',

    GET_DETAILS_CU_BASED_CHART: 'GET_DETAILS_CU_BASED_CHART',
    GET_DETAILS_CU_BASED_CHART_SUCCESS: 'GET_DETAILS_CU_BASED_CHART_SUCCESS',
    GET_DETAILS_CU_BASED_CHART_FAIL: 'GET_DETAILS_CU_BASED_CHART_FAIL',
    GET_DETAILS_CU_BASED_CHART_EXCEPTION: 'GET_DETAILS_CU_BASED_CHART_EXCEPTION',

    GET_ENDPOINTS: 'GET_ENDPOINTS',
    GET_ENDPOINTS_SUCCESS: 'GET_ENDPOINTS_SUCCESS',
    GET_ENDPOINTS_FAIL: 'GET_ENDPOINTS_FAIL',
    GET_ENDPOINTS_EXCEPTION: 'GET_ENDPOINTS_EXCEPTION',

    GET_EDIT_DEDICATED: 'GET_EDIT_DEDICATED',
    GET_EDIT_DEDICATED_SUCCESS: 'GET_EDIT_DEDICATED_SUCCESS',
    GET_EDIT_DEDICATED_FAIL: 'GET_EDIT_DEDICATED_FAIL',
    GET_EDIT_DEDICATED_EXCEPTION: 'GET_EDIT_DEDICATED_EXCEPTION',
  
    POST_EDIT_DEDICATED: 'POST_EDIT_DEDICATED',
    POST_EDIT_DEDICATED_SUCCESS: 'POST_EDIT_DEDICATED_SUCCESS',
    POST_EDIT_DEDICATED_FAIL: 'POST_EDIT_DEDICATED_FAIL',
    POST_EDIT_DEDICATED_EXCEPTION: 'POST_EDIT_DEDICATED_EXCEPTION',
  
    
    SHOW_EDIT_DIDICATED_MODAL: 'SHOW_EDIT_DIDICATED_MODAL',
   
  };