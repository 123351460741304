import React, { useRef, useState } from "react";
import { FooterWrapper } from "./styles";

const Footer = (props) => {
    return (
        <FooterWrapper>
            تمامی حقوق و امتیازات این وبسایت متعلق به
            <a href="https://polaristech.ir/">پلاریس </a>
            است
        </FooterWrapper>
    )
}
export default Footer