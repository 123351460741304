// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
    text-align: center;
  }
  
  button {
    background: #FCA311;
    border:1px solid #FCA311;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    position: relative;
    padding: 0 16px;
    width : 100%;
    min-height:40px;
  }
  
  /* button:active {
    background: #f79801;
  } */
  
  .text {
    font-weight: bold;
    transition: opacity 250ms;
    transition-delay: 250ms;
    width: 100%;
  }
  
  button.loading .text {
    opacity: 0;
    transition-delay: 0s;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    border-top-color: white;
    opacity: 0;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    width: 16px;
    height: 16px;
    transition: opacity 250ms;
    animation: rotate-spinner 1s linear;
    animation-iteration-count: infinite;
  }
  
  button.loading .spinner {
    opacity: 1;
    transition-delay: 250ms;
  }
  .disabled-button {
    background-color: #fca3118a; /* Gray background color */
}
  @keyframes rotate-spinner {
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/kit/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,wBAAwB;IACxB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,eAAe;EACjB;;EAEA;;KAEG;;EAEH;IACE,iBAAiB;IACjB,yBAAyB;IACzB,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE,UAAU;IACV,oBAAoB;EACtB;;EAEA;IACE,0CAA0C;IAC1C,kBAAkB;IAClB,uBAAuB;IACvB,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mCAAmC;IACnC,mCAAmC;EACrC;;EAEA;IACE,UAAU;IACV,uBAAuB;EACzB;EACA;IACE,2BAA2B,EAAE,0BAA0B;AAC3D;EACE;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".app {\n    text-align: center;\n  }\n  \n  button {\n    background: #FCA311;\n    border:1px solid #FCA311;\n    border-radius: 10px;\n    color: white;\n    cursor: pointer;\n    position: relative;\n    padding: 0 16px;\n    width : 100%;\n    min-height:40px;\n  }\n  \n  /* button:active {\n    background: #f79801;\n  } */\n  \n  .text {\n    font-weight: bold;\n    transition: opacity 250ms;\n    transition-delay: 250ms;\n    width: 100%;\n  }\n  \n  button.loading .text {\n    opacity: 0;\n    transition-delay: 0s;\n  }\n  \n  .spinner {\n    border: 4px solid rgba(255, 255, 255, 0.5);\n    border-radius: 50%;\n    border-top-color: white;\n    opacity: 0;\n    position: absolute;\n    left: 25%;\n    right: 25%;\n    top: 25%;\n    bottom: 25%;\n    margin: auto;\n    width: 16px;\n    height: 16px;\n    transition: opacity 250ms;\n    animation: rotate-spinner 1s linear;\n    animation-iteration-count: infinite;\n  }\n  \n  button.loading .spinner {\n    opacity: 1;\n    transition-delay: 250ms;\n  }\n  .disabled-button {\n    background-color: #fca3118a; /* Gray background color */\n}\n  @keyframes rotate-spinner {\n    100% {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
