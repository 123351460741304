import types from "./Types";
import Storage from "src/storage";
import Keys from "src/storage/NameSpace";
const initState = {
  cuAndDuration: Storage.get(Keys.cuAndDuration) ? Storage.get(Keys.cuAndDuration) : 0,
  unit: Storage.get(Keys.unit) ? Storage.get(Keys.unit) : 0,
  priceList: [],
  paymentStep: Storage.get(Keys.paymentStep) ? Storage.get(Keys.paymentStep) : 1,

  takePaymentHistory: 1000,
  skipPaymentHistory: 0,
  paymentHistoryList: [],
  paymentHistoryLoading: true,
  hasMore: false,
  count: 0,
  invoiceid: Storage.get(Keys.invoiceid) ? Storage.get(Keys.invoiceid) : null,
  paymentResult: "error"

};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.ADD_COMPUTATION_UNIT_SUCCESS:
      return {
        ...state,
        cuAndDuration: 0,
        unit: 0,
      };
    case types.GET_CU_SUCCESS:
      Storage.set(Keys.cuAndDuration, action.data.data);
      Storage.set(Keys.unit, action.data.unit);

      return {
        ...state,
        cuAndDuration: action.data.data,
        unit: action.data.unit,
      };
    case types.GET_CU_FAIL:
      Storage.set(Keys.cuAndDuration, 0);
      Storage.set(Keys.unit, 0);
      return {
        ...state,
        cuAndDuration: 0,
        unit: 0,
      };
    case types.GET_PRICE_LIST_SUCCESS:
      return {
        ...state,
        priceList: action.data.data
      };
    case types.NEXT_PAYMENT_STEP:
      Storage.set(Keys.paymentStep, state.paymentStep + 1);
      return {
        ...state,
        paymentStep: state.paymentStep + 1
      };
    case types.PREV_PAYMENT_STEP:
      Storage.set(Keys.paymentStep, state.paymentStep - 1);
      return {
        ...state,
        paymentStep: state.paymentStep - 1
      };
    case types.GET_PAYMENT_LIST_SUCCESS:
      let newpaymentHistoryList = [...state.paymentHistoryList];
      newpaymentHistoryList = [...action.data.data.payments.items];
      return {
        ...state,
        paymentHistoryLoading: false,
        paymentHistoryList: newpaymentHistoryList,
        skipPaymentHistory: action.data.skip + state.takePaymentHistory,
        count: action.data.data.payments.totalItemsCount,
        hasMore: action.data.skip + state.takePaymentHistory < action.data.data.payments.totalItemsCount ? true : false,
      };
    case types.MAKE_INVOICE_SUCCESS:
      Storage.set(Keys.invoiceid, action.data);
      return {
        ...state,
        invoiceid: action.data
      };
    case types.DO_PAYMENT_SUCCESS:
      return {
        ...state,
      };
    case types.RESET_PAYMENT_DATA:
      Storage.clearPaymentInfo();
      return{
        ...state,
        paymentStep:1
      }
    default:
      return state;
  }
}