import React, { useEffect } from "react";
import { Modal, Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import CloseIcon from "src/assets/icons/CloseIcon";
import { FormContainer } from "../styles";
import { useSelector } from "react-redux";
// import { VerifyValidationAddEndpoint } from "src/validation/schemas/verifyValidationAddEndpoint";
import VirtualizedSelect from 'react-virtualized-select';
import Button from "src/kit/Button";

const EditEndpointModal = (props) => {
    const { show,
        onclose,
        title,
        onConfirm,
        editDedicatedHostDetail
    } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const direction = useSelector((state) => state.App.direction);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? "90vw" : "60vw",
        height: isSmallScreen ? "auto" : "auto",
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
        direction: direction
    };
    const enableOptions = [
        { value: true, label: "فعال" },
        { value: false, label: "غیرفعال" },

    ]

    return (
        <Formik
            initialValues={{ ...editDedicatedHostDetail }}
            isInitialValid={false}
            // validationSchema={VerifyValidationAddEndpoint}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                onConfirm(values, action);
            }}
        >
            {(formik) => (

                <Modal
                    open={show}
                    onClose={() => onclose(formik)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <Grid item container xs={12} id="modal_title" p={2}>
                            <Grid item xs={11} display="flex" justifyContent="start">
                                {title}
                            </Grid>
                            <Grid item xs={1} onClick={() => onclose(formik)} className="clickable" display="flex" justifyContent="end">
                                <CloseIcon />
                            </Grid>
                        </Grid>
                        <FormContainer direction={direction}>
                            <form className="py-5" onSubmit={formik.handleSubmit}>
                                <Grid container display="flex" justifyContent="start">

                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">وضعیت</Box>
                                        <VirtualizedSelect
                                                value={formik.values.isEnable}
                                                name="isEnable"
                                                id="isEnable"
                                                placeholder="انتخاب کنید"
                                                onChange={(selected) => {

                                                    FormController.onSelect(selected, "isEnable", formik, null, true);
                                                }}
                                                maxHeight={150}
                                                options={enableOptions}
                                                multi={false}
                                                className={
                                                    "custom_input" + (formik.errors.isEnable && formik.touched.isEnable ? " warning-border" : "")
                                                }
                                                backspaceToRemoveMessage=""
                                            />
  
                                    </Grid>
                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">Url</Box>
                                        <FormInput
                                            value={formik.values.rpcUrl}
                                            name="rpcUrl"
                                            id="rpcUrl"
                                            placeholder="Url"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.rpcUrl && formik.touched.rpcUrl ? " warning-border" : "")
                                            }
                                        />
                                    </Grid>
                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">نام</Box>
                                        <FormInput
                                            value={formik.values.name}
                                            name="name"
                                            id="name"
                                            placeholder="نام"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.name && formik.touched.name ? " warning-border" : "")
                                            }
                                        />
                                    </Grid>
                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">ترتیب</Box>
                                        <FormInput
                                            value={formik.values.order}
                                            name="order"
                                            id="order"
                                            placeholder="ترتیب"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.order && formik.touched.order ? " warning-border" : "")
                                            }
                                        />
                                    </Grid>
                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">وزن</Box>
                                        <FormInput
                                            value={formik.values.weight}
                                            name="weight"
                                            id="weight"
                                            placeholder="وزن"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.weight && formik.touched.weight ? " warning-border" : "")
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container display="flex" justifyContent="end">
                                    <Grid item xs={4} p={1} md={4} >
                                        <Button
                                            onClick={formik.handleSubmit}
                                            text="ثبت"
                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContainer>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default EditEndpointModal