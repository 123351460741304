import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import Button from "src/kit/Button";
import CloseIcon from "src/assets/icons/CloseIcon";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
};

const ResultModal = ({
    open,
    onClose,
}) => {

    return (
        <Modal
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>

                <Grid item container xs={12} p={2} sx={{ borderBottom: 1, borderColor: 'grey.500' }}>
                    <Grid item xs={11} display="flex" justifyContent="start">

                    </Grid>
                    <Grid item xs={1} onClick={() => onClose(false)} className="clickable">
                        <CloseIcon />
                    </Grid>
                </Grid>

                <Grid container p={2}>
                    <Grid item xs={12} p={1} sx={{ textAlign: "justify" }}>
                        هایپرلجر فابریک اختصاصی با موفقیت ثبت شد، بعد از بررسی
                        و راه اندازی شبکه اختصاصی از طریق ایمیل با شما در ارتباط خواهیم بود.
                    </Grid>
                    <Grid item xs={12} py={2}>
                        <Button
                            onConfirm={() => { onClose(false) }}
                            text="تایید"
                        >
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
export default ResultModal;