
import React, { useState } from "react";
import { Modal, Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import CloseIcon from "src/assets/icons/CloseIcon";
import { FormContainer } from "./styles"
// import { VerifyValidationMenu } from "src/validation/schemas/verifyValidationMenu";
import VirtualizedSelect from 'react-virtualized-select';
import { useSelector, useDispatch } from "react-redux";
import EndpointsActions from "src/redux/Endpoint/Actions";
import WalletActions from "src/redux/wallet/Actions";


const data = [
    {
        value: 1, label: "کیف پول جدید", extractable: true,
        formLabel: [], type: "input"
    },
    {
        value: 2, label: "اضافه کردن از طریق Mnmonics", extractable: true,
        formLabel: [{ label: "Mnemonic", value: "Mnemonic" }], type: "input"
    },
    {
        value: 3, label: "اضافه کردن از طریق Private Key", extractable: true,
        formLabel: [{ label: "Private Key Hex", value: "privateKeyHex" }, { label: "Chain Code Hex", value: "chainCodeHex" }], type: "input"
    },
    {
        value: 4, label: "اضافه کردن از طریق Secret Key", extractable: true,
        formLabel: [{ label: "Secret Key", value: "secretKeyHex" }], type: "input"
    },
    {
        value: 5, label: "اضافه کردن از طریق Key Id", extractable: false,
        formLabel: [{ label: "Kms Key Id", value: "kmsKeyId" }], type: "input"
    },
    {
        value: 6, label: "کیف پول اشتقاقی", extractable: true,
        formLabel: [{ label: "کیف پول اصلی", value: "parenWalletId" }], type: "select"
    },

]

const WalletDetailModal = (props) => {
    const { show,
        onclose,
        title,
        fields,
        onConfirm,
        selected,
        setSelected
    } = props;
    const theme= useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
   
    const endpointState = useSelector((state) => state.Endpoint);
    const { blockchainsListOptions, networksListOptions } = endpointState;
    const { parentWalletsOptions } = useSelector((state) => state.Wallet);
    const dispatch = useDispatch();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? "90vw" : "60vw",
        height : isSmallScreen ? "90vh" : "auto",
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
        overflowY : isSmallScreen ? "auto" : "auto",
    };

    return (
        <Formik
            initialValues={{ ...fields }}
            isInitialValid={false}
            // validationSchema={VerifyValidationMenu}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                onConfirm(values, action);
            }}
        >
            {(formik) => (

                <Modal
                    open={show}
                    onClose={() => onclose(formik)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <Grid item container xs={12} id="modal_title" p={2}>
                            <Grid item xs={11} display="flex" justifyContent="start">
                                {title}
                            </Grid>
                            <Grid item xs={1} onClick={() => onclose(formik)} className="clickable" display="flex" justifyContent="end">
                                <CloseIcon />
                            </Grid>
                        </Grid>
                        <FormContainer>
                            <form className="py-5" onSubmit={formik.handleSubmit}>
                                <Grid container display="flex" justifyContent="end">
                                    <Grid item p={1} md={6} xs={12}>
                                        <Box className="form_lable">بلاکچین</Box>
                                        <VirtualizedSelect
                                            noResultsText="موردی وجود ندارد"
                                            value={formik.values.blockchainId}
                                            name="blockchainId"
                                            id="blockchainId"
                                            placeholder="انتخاب کنید"
                                            onChange={(selected) => {
                                                
                                                if(selected !== null){
                                                    dispatch(EndpointsActions.getNetworksList({ id: selected.value }))
                                                    FormController.onSelect(selected, "blockchainId", formik, null, true);
                                                }
                                                else{
                                                    FormController.onSelect("", "blockchainId", formik, null, true);
                                                }
                                             
                                            }}
                                            maxHeight={150}
                                            options={blockchainsListOptions}
                                            multi={false}
                                            className={
                                                "custom_input" + (formik.errors.blockchainId && formik.touched.blockchainId ? " warning-bottom-border" : "")
                                            }
                                            backspaceToRemoveMessage=""
                                        />
                                        {formik.errors.blockchainId && formik.touched.blockchainId ? (
                                            <div className="text-alert">{formik.errors.blockchainId}</div>
                                        ) : <div className="text-alert"></div>}
                                    </Grid>
                                    <Grid item p={1} md={6} xs={12}>
                                        <Box className="form_lable">شبکه</Box>
                                        <VirtualizedSelect
                                            noResultsText="موردی وجود ندارد"
                                            value={formik.values.networkId}
                                            name="networkId"
                                            id="networkId"
                                            placeholder="انتخاب کنید"
                                            onChange={(selected) => {
                                                if(selected !== null){
                                                FormController.onSelect(selected, "networkId", formik, null, true);
                                                dispatch(WalletActions.getParentWallet({ networkId: selected.value ,blockchainId :formik.values.blockchainId}))
                                                }
                                                else{
                                                FormController.onSelect("", "networkId", formik, null, true);

                                                }
                                            }}
                                            maxHeight={150}
                                            options={networksListOptions}
                                            multi={false}
                                            className={
                                                "custom_input" + (formik.errors.networkId && formik.touched.networkId ? " warning-bottom-border" : "")
                                            }
                                            backspaceToRemoveMessage=""
                                        />
                                        {formik.errors.networkId && formik.touched.networkId ? (
                                            <div className="text-alert">{formik.errors.networkId}</div>
                                        ) : <div className="text-alert"></div>}

                                    </Grid>
                                    <Grid item p={1} md={6} xs={12}>
                                        <Box className="form_lable">نام</Box>
                                        <FormInput
                                            value={formik.values.label}
                                            name="label"
                                            id="label"
                                            placeholder="نام"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.label && formik.touched.label ? " warning-border" : "")
                                            }
                                        />
                                        {formik.errors.label && formik.touched.label ? (
                                            <div className="text-alert">{formik.errors.label}</div>
                                        ) : <div className="text-alert"></div>}
                                    </Grid>
                                    <Grid item p={1} md={6} xs={12}>
                                        <Box className="form_lable">کلید API</Box>
                                        <FormInput
                                            value={formik.values.apiKey}
                                            name="apiKey"
                                            id="apiKey"
                                            placeholder="کلید API"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.apiKey && formik.touched.apiKey ? " warning-border" : "")
                                            }
                                        />
                                        {formik.errors.apiKey && formik.touched.apiKey ? (
                                            <div className="text-alert">{formik.errors.apiKey}</div>
                                        ) : <div className="text-alert"></div>}
                                    </Grid>
                                    <Grid item p={1} md={6} xs={12}>
                                        {
                                            data.map((each, index) => {
                                                return (
                                                    <Box key={index} py={1}>
                                                        <input type="radio" id="type" name="type" value={each.value} checked={each.value == formik.values.type && true}
                                                            onChange={(e) => { setSelected(Number(e.target.value)); FormController.onChange(e, formik, null) }} />
                                                        <label for={each.value}>{each.label}</label><br></br>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid item p={1} md={6} xs={12}>
                                        {data !== null && data[selected - 1]?.extractable === true && <Box py={1}>
                                            <input type="checkbox" id="exportable" name="exportable" onChange={(e) => FormController.onChange(e, formik, null)} />
                                            <label for="extractable">قابل استخراج</label><br></br>
                                        </Box>}
                                        {
                                            data !== null && data[selected - 1]?.type !== "select" ? data[selected - 1]?.formLabel.map((each, index) => {

                                                return (
                                                    <Box py={1}>
                                                        <FormInput
                                                            // value={formik.values.title}
                                                            name={each.value}
                                                            id={each.value}
                                                            placeholder={each.label}
                                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                                            className={
                                                                "custom_input" + (formik.errors.title && formik.touched.title ? " warning-border" : "")
                                                            }
                                                        />
                                                    </Box>
                                                )
                                            }) : data[selected - 1]?.formLabel.map((each, index) => {

                                                return (
                                                    <Box py={1}>
                                                        <VirtualizedSelect
                                                            value={formik.values[each['value']]}
                                                            name={each.value}
                                                            id={each.value}
                                                            placeholder={each.label}
                                                            onChange={(selected) => {
                                                                FormController.onSelect(selected, each['value'], formik, null, true);
                                                            }}
                                                            maxHeight={150}
                                                            options={parentWalletsOptions}
                                                            multi={false}
                                                            backspaceToRemoveMessage=""
                                                            noResultsText="موردی وجود ندارد"
                                                            disabled = {(formik.values.networkId ==="" && formik.values.blockchainId ==="") ? true : false}
                                                            />
                                                            {(formik.values.networkId ==="" && formik.values.blockchainId ==="") ?
                                                            <Box fontSize={12} color="red">
                                                            برای انتخاب ولت در حالت کیف پول اشتقاقی نیاز هست که ابتدا بلاکچین و شبکه آن مشخص شود.
                                                            
                                                            </Box> : <></>}
                                                            
                                                    </Box>
                                                )
                                            })
                                        }

                                    </Grid>
                                    <Grid item xs={4} p={1} md={4} >
                                        <button
                                            color="primary"
                                            size="lg"
                                            onClick={formik.handleSubmit}
                                            className="ml-1 btn-form"
                                        >
                                            <Box className="color-white" p={2}>ثبت</Box>
                                        </button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContainer>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default WalletDetailModal