export const NumberSeprator = (num) => {
    return Math.ceil(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  };
  
export const prepareSelectData = (type, info, moreOption = "") => {

    let exportInformation = [];
  
    if (type === "endpoint") {
      for (let row of info) {
          exportInformation.push({
            value: row.id,
            label: row.name,
          });
      }
    }
    if (type === "endpoints") {
      for (let row of info) {
          exportInformation.push({
            value: row.id,
            label: row.description,
          });
      }
    }
    if (type === "parenrWallet") {
      for (let row of info) {
          exportInformation.push({
            value: row.id,
            label: row.label,
          });
      }
    }
    if (type === "organizations") {
      for (let row of info) {
          exportInformation.push({
            value: row.MSPId,
            label: row.orgName,
          });
      }
    }
    if (type === "peers") {
      for (let row of info) {
          exportInformation.push({
            value: row.name,
            label: row.name,
          });
      }
    }
    
    else if (type === "category") {
        for (let row of info) {
          exportInformation.push({
            value: row.id,
            fa_name: row.fa_name,
            en_name: row.en_name,
            label : row.fa_name
          });
        }
      }


    return exportInformation;
  }

  export const hexToImage = (hex) => {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 100; // Set width of canvas
    canvas.height = 100; // Set height of canvas
  
    // Get the 2D context of the canvas
    const ctx = canvas.getContext('2d');
  
    // Fill the canvas with the hex color
    ctx.fillStyle = hex;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Convert canvas to data URL
    const dataURL = canvas.toDataURL();
  
    return dataURL;
  };

  export const group = (arr, key) => {
    return [...arr.reduce((acc, o) =>
      acc.set(o[key], (acc.get(o[key]) || []).concat(o))
      , new Map).values()];
  }