import React from "react";
import crtl from "../../hyperledger.controllers";
import { Grid, Box } from "@mui/material";
import { FormInput } from "src/kit/Form";
import { FormContainer } from "../../style";

const PeerInputField = ({ label, placeholder, value, name, id, onChange, errorMessage, touched }) => {
    const hasError = errorMessage && touched;

    return (
        <Grid item p={2} xs={12} md={6}>
            <Box className="form_label">{label}</Box>
            <FormInput
                value={value}
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                className={`custom_input ${hasError ? "warning-border" : ""}`}
            />
            {hasError ? <div className="text-alert">{errorMessage}</div> :  <div className="text-alert"></div>}
        </Grid>
    );
};

const PeerInfo = ({ formik, entry, entryIndex }) => {
    const { handlePeerChange } = crtl();
    const { errors, touched, handleSubmit } = formik;

    return (
        <FormContainer>
            <form className="py-5" onSubmit={handleSubmit}>
                {entry.peers.map((each, index) => (
                    <Grid container display="flex" justifyContent="end" key={index}>
                        <PeerInputField
                            label="نام peer"
                            placeholder="نام peer"
                            value={each.name}
                            name="name"
                            id="name"
                            onChange={(e) => handlePeerChange(e, formik, null, entry, entryIndex, index)}
                            errorMessage={errors.entries?.[entryIndex]?.peers?.[index]?.name}
                            touched={touched.entries?.[entryIndex]?.peers?.[index]?.name}
                        />
                        <PeerInputField
                            label="پورت peer"
                            placeholder="پورت peer"
                            value={each.port}
                            name="port"
                            id="port"
                            onChange={(e) => handlePeerChange(e, formik, null, entry, entryIndex, index)}
                            errorMessage={errors.entries?.[entryIndex]?.peers?.[index]?.port}
                            touched={touched.entries?.[entryIndex]?.peers?.[index]?.port}
                        />
                    </Grid>
                ))}
            </form>
        </FormContainer>
    );
};

export default PeerInfo;
